import { createRouter, createWebHashHistory } from "vue-router";

const routeList = [];
const clean = (arg) => {
  var _result = [];
  arg.forEach((r) => {
    for (var key in r) {
      if (r[key] instanceof Object) {
        if (Object.keys(r[key]).length) {
          _result.push(r[key]);
        }
      }
    }
  });
  return _result;
};

function importAll(r) {
  r.keys().forEach((key) => {
    var _list = [];
    _list.push(r(key).default);
    routeList.push(clean(_list));
  });
}

importAll(require.context("../router", true, /\.routes\.js/));

const routes = [
  {
    path: "/",
    redirect: "/Home",
  },
  {
    path: "/Home",
    name: "Home",
    component: () => import("../views/home"),
    meta: {
      keepAlive: false,
      requireLogin: false,
    },
  },
  {
    path: "/Home_",
    name: "Home_",
    component: () => import("../views/home_"),
    meta: {
      keepAlive: false,
      requireLogin: false,
    },
  },
  {
    path: "/About",
    name: "About",
    component: () => import("../views/about"),
    meta: {
      keepAlive: false,
      requireLogin: false,
    },
  },
  {
    path: "/Contact",
    name: "Contact",
    component: () => import("../views/contact"),
    meta: {
      keepAlive: false,
      requireLogin: false,
    },
  },
  {
    path: "/Solution",
    name: "Solution",
    component: () => import("../views/solution"),
    meta: {
      keepAlive: false,
      requireLogin: false,
    },
  },
];

routeList.forEach((item) => {
  item.forEach((r) => {
    routes.push(r);
  });
});

const router = createRouter({
  history: createWebHashHistory("/Home"),
  // base: process.env.BASE_URL,
  routes,
  strict: true,
  scrollBehavior: () => ({
    left: 0,
    top: 0,
  }),
});

router.onError((err) => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = err.message.match(pattern);
  if (isChunkLoadFailed) {
    window.location.reload();
    // router.replace(router.history.pending.fullPath);
  } else {
    console.log(err);
    alert(err);
  }
});

//在路由跳转前用NProgress.start()标记下进度条开始
router.beforeEach((to, from, next) => {
  next();
});

export default router;
