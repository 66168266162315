/*
 * @Author: lidanhua
 * @Date: 2022-02-24 15:33:02
 * @LastEditors: lidanhua
 * @Description:
 * @FilePath: \vue.ecrd:\webpack\dgrsafe\src\main.js
 *
 *
 */
import { createApp } from "vue";
import ElementPlus from "element-plus";
import router from "./router/index";
import "element-plus/dist/index.css";
import App from "./App.vue";
import "@/assets/scss/style.scss";
import "@/assets/scss/header.scss";
import "@/assets/scss/media.scss";

const app = createApp(App);
app.use(router);
app.use(ElementPlus);
app.mount("#app");
